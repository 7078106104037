import { Guid } from 'guid-typescript'
import { Store } from 'vuex'

import { MarketingSearchState } from './children/marketingSearchState'
import { Route } from '@/ts/models/route'
import { SMSMode } from '@/ts/enums/smsMode'

import _ from 'lodash'

/**
 * State management of Marketing searches and UI
 * @class
 */
export class MarketingState {

  /**
   * The current SMSMode for joining all search results.
   * This could be Unique records or Duplicate records. 
   * @property
   * @see {@link SMSMode}
   * @returns {SMSMode}
   */
  public mode = SMSMode.UNIQUE
 

  /**
   * The navigation stack
   * @private
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  private routes: Route[]

  /**
   * The collection of Marketing Search states which have been performed 
   * @private
   * @property
   * @see {@link MarketingSearchState}
   * @returns {MarketingSearchState[]}
   */
  private searchStates: MarketingSearchState[]

  /**
   * A back-end store for the Vuex local session storage
   * @private
   * @property
   * @see {@link Store}
   * @returns {Store<any>}
   */
  private store: Store<any>


  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.routes = new Array<Route>()
    this.searchStates = [new MarketingSearchState(store)]
    this.store = store
  }


  /**
   * The total number of searches performed
   * @property
   * @returns {number}
   */
  public get count() {
    return this.searchStates.length
  }

  /**
   * The number of duplicate records returned across all the searches
   * @property
   * @returns {number}
   */
  public get duplicateCount() {
    return this.totalCount - this.uniqueCount
  }

  /**
   * Whether any duplicate records exist between the searches 
   * @property
   * @returns {boolean}
   */
  public get hasDuplicates() {
    return this.duplicateCount > 0
  }

  /**
   * Whether any records have been found which match the searches performed
   * @property
   * @returns {boolean}
   */
  public get hasResults() {
    return this.totalCount > 0
  }

  /**
   * The distinct set of Patrons who have been found which match the searches performed
   * @property
   * @returns {Patron[]}
   * @see {@link Patron}
   */
  public get patrons() {
    const patrons = this.searchStates.map(s => s.patronIds)
    const set = new Set(patrons.flat())

    return Array.from(set)
  }


  /**
   * Either the distinct set of Patrons, or the duplicate patrons
   * who have been found which match the searches performed
   * @property
   * @returns {Patron[]}
   * @see {@link Patron}
   */
  public get recipients() {
    if (this.mode == SMSMode.DUPLICATE) {
      const patrons = this.searchStates.map(s => s.patronIds).flat()
      return _.filter(patrons, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
    }

    return this.patrons
  }

  /**
   * The number of recipients the SMS message will be sent to
   * @property
   * @returns {number}
   */
  public get recipientCount() {
    return (this.mode == SMSMode.DUPLICATE) ? this.duplicateCount : this.uniqueCount
  }

  /**
   * All Marketing Search states which have been performed
   * @property
   * @returns {MarketingSearchState[]}
   * @see {@link MarketingSearchState}
   */
  public get states() {
    return this.searchStates
  }

  /**
   * The total number of records returned from all searches
   * @property
   * @returns {number}
   */
  public get totalCount() {
    const patrons = this.searchStates.map(s => s.patronIds).flat()
    return patrons.length
  }

  /**
   * The number of distinct records returned across all searches
   * @property
   * @returns {number}
   */
  public get uniqueCount() {
    return this.patrons.length
  }


  /**
  * Add a new Marketing Search state to the collection of states (search added).
  * @function
  * @returns {void}
  */
  public add() {
    this.searchStates.push(new MarketingSearchState(this.store))
  }

  /**
  * Remove an existing Marketing Search state from the collection of states.
  * @function
  * @returns {void}
  */
  public clear() {
    this.searchStates = [new MarketingSearchState(this.store)]
  }
  
  /**
  * Clears the Terminal Locations currently retrieved and resets pagination
  * @function
  * @returns {void}
  */
  public isFirst(state: MarketingSearchState) {
    const first = _.first(this.searchStates)!
    return (!first) ? false : state.key == first.key
  }

  /**
  * Clears the Terminal Locations currently retrieved and resets pagination
  * @function
  * @returns {void}
  */
  public isLast(state: MarketingSearchState) {
    const last = _.last(this.searchStates)
    return (!last) ? false : state.key == last.key
  }

  /**
  * Clears the Terminal Locations currently retrieved and resets pagination
  * @function
  * @returns {void}
  */
  public popRoute() {
    const route = _.last(this.routes)
    return route
  }

  /**
  * Add a route to the navigation stack.
  * @example
  * @function
  * @returns {void}
  */
  public pushRoute(route: Route) {
    this.routes.push(route)
  }

  /**
  * Remove an existing Marketing Search state from the collection of states.
  * @function
  * @param {Guid} key -The unique identifier of the search
  * @returns {void}
  */
  public remove(key: Guid) {
    this.searchStates = this.searchStates.filter(s => s.key != key)
    if (this.searchStates.length == 0) {
      this.clear()
    }
  }
}
