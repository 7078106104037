
  import { Component, Vue } from 'vue-property-decorator'
  import { Guid } from 'guid-typescript'
  import { MarketingSearchState } from '@/ts/states/marketing/children/marketingSearchState'
  import { MarketingState } from '@/ts/states/marketing/marketingState'

  import MarketingFooter from './components/MarketingFooter.vue'
  import MarketingSearchForm from './components/MarketingSearchForm.vue'
  import SendSmsForm from '@/components/forms/SendSmsForm.vue'
  import UnsubscribeForm from './components/UnsubscribeForm.vue'

  @Component({
    components: {
      MarketingFooter,
      MarketingSearchForm,
      SendSmsForm,
      UnsubscribeForm
    }
  })
  export default class MarketingSearch extends Vue {

    /** PRIVATE PROPERTIES */

    protected state = new MarketingState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get hasResults() {
      return this.state.hasResults
    }

    protected isFirst(state: MarketingSearchState) {
      return this.state.isFirst(state)
    }

    protected isLast(state: MarketingSearchState) {
      return this.state.isLast(state)
    }

    protected get patronIds() {
      return this.state.recipients
    }

    protected get showRemove() {
      return this.state.count > 1
    }


    /** EVENTS */

    protected onClear() {
      this.state.clear()
    }

    protected onClick() {
      this.state.add()
    }

    protected onReset(key: Guid) {
      this.state.remove(key)
    }
  }

