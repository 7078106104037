
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { MarketingState } from '@/ts/states/marketing/marketingState'

  @Component
  export default class MarketingFooter extends Vue {

  /** PROPERTIES */

    @Prop({ default: false })
    public showPanel!: boolean

    @Prop()
    public state!: MarketingState


  /** PRIVATE PROPERTIES */

    protected slideIn = false
    protected slideOut = false
    

  /** OBSERVERS */

    @Watch('showPanel', { immediate: true })
    protected onShowPanelChanged(show: boolean) {
      if (show && this.slideIn == true) {
        this.slideOut = !show
      }

      this.slideIn = show
    }

  /**  EVENTS  */
    protected onClick() {
      this.showPanel = false
    }
  }

