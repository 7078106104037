
  import { Component, Vue } from 'vue-property-decorator'
  import { Patron } from '@/ts/models/patron';
  import { PatronRepository } from '@/ts/repositories/patronRepository';
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component
  export default class UnsubscribeForm extends Vue {

    /** PROPERTIES */

    protected phoneNumber = ''
    protected patron: Patron | null = null
    private repo = new PatronRepository()
    private status = ViewStatus.NONE


    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get patronText() {
      if (this.status > ViewStatus.IN_PROGRESS && this.patron == null) {
        return 'No results found'
      }

      if (this.status == ViewStatus.SUCCEEDED && this.patron != null) {
        return `${this.patron.person!.fullname ?? ''} ${this.maskedPhone(this.patron.person!.mobile!)}`
      }


      return ''
    }

    protected get searchText() {
      return (this.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Search'
    }

    protected async onSubmit() {
      this.status = ViewStatus.IN_PROGRESS

      try {
        this.patron = await this.repo.getPatronByMobile(this.phoneNumber)
        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        this.status = ViewStatus.FAILED
        console.log(error)
      }
    }

    /** EVENTS */

    protected async onUnsubscribe() {
      try {
        await this.repo.unsubscribe(this.patron!.id)
        this.patron = null
        this.phoneNumber = ''
        this.status = ViewStatus.NONE
      }
      catch (error) {
        console.log(error)
      }
    }


    /** PRIVATE METHODS */

    protected maskedPhone(num: string) {
      return `${num.substr(0, 2)}${'*'.repeat(num.length - 6)}${num.substr(num.length - 4)}`
    }

  }
