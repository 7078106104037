import { Guid } from 'guid-typescript'
import { Store } from 'vuex'

import { EventType } from '@/ts/models/eventType'
import { Gender } from '@/ts/models/gender'
import { LookupState } from '@/ts/states/lookup/lookupState'
import { Patron } from '@/ts/models/patron'
import { PatronRepository } from '@/ts/repositories/patronRepository'
import { QueryParams } from '@/ts/api/queryParams'
import { TerminalLocation } from '@/ts/models/terminalLocation'
import { ViewStatus } from '@/ts/enums/viewStatus'

/**
 * State management of Organisation searches and UI
 * @class
 */
export class MarketingSearchState {

  /**
   * A list of pre-defined Event Types.
   * @property
   * @see {@link EventType}
   * @returns {EventType[]}
   */
  public eventTypes?= new Array<EventType>()

  /**
   * A list of pre-defined Genders.
   * @property
   * @see {@link Gender[]}
   * @returns {Gender}
   */
  public gender?= new Array<Gender>()

  /**
   * Whether the UI is in advanced search mode.
   * @property
   * @returns {boolean}
   */
  public isAdvancedSearch = false

  /**
   * A unique key for this search
   * @property
   * @returns {Guid}
   */
  public readonly key = Guid.create()

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = this.newParams()

  /**
   * A single Patron who has been found, typcially from a Mobile search.
   * This should mostly be used to unsubscribe a Patron
   * @property
   * @see {@link Patron}
   * @returns {Patron?}
   */
  public patron?: Patron

  /**
   * The list of PatronIds who are currently flagged to send an SMS to.
   * @property
   * @returns {number[]}
   */
  public patronIds = new Array<number>()

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE

  /**
   * A list of Terminal Locations for the current Organisation.
   * @property
   * @see {@link TerminalLocation}
   * @returns {TerminalLocation[]}
   */
  public terminalLocations?= new Array<TerminalLocation>()


  /**
  * The LookupState for retrieving and displaying pre-defined
  * values in the UI
  * @private
  * @property
  * @returns {LookupState}
  */
  private lookupState: LookupState

  /**
  * The private repository used to retrieve data from the API
  * @private
  * @property
  * @see {@link PatronRepository}
  * @returns {PatronRepository}
  */
  private repo = new PatronRepository()



  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  public constructor(store: Store<any>) {
    this.lookupState = new LookupState(store)
    this.lookup()
  }

  
  /**
  * Clears the current state including Query Params and the status
  * @function
  * @returns {void}
  */
  public clear() {
    this.isAdvancedSearch = false
    this.patronIds = []
    this.queryParams = new QueryParams()
    this.status = ViewStatus.NONE
  }

  /**
   * Retrieves a list of Patron Ids from the API that match a set of
   * speficied criteria as found in the Query Params
   * @function
   * @param {QueryParams} queryParams - the list of Query Params to sort and filter on
   * @returns {number[]} A list of Patron Ids
   */
  public async fetch(queryParams: QueryParams) {
    try {
      this.status = ViewStatus.IN_PROGRESS
      this.patronIds = await this.repo.getPatrons(queryParams)
      this.status = ViewStatus.SUCCEEDED

      if (this.patronIds.length == 1) {
        this.get(this.patronIds[0])
      }
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }
 
  /**
   * Retrieves a single Patron from the API based on their unique Id,
   * or null if none is found.  This will set the property Patron of
   * the current State
   * @function
   * @param {number} id - The unique Id of the Patron
   * @returns {void}
   */
  private async get(id: number) {
    try {
      this.status = ViewStatus.IN_PROGRESS
      this.patron = await this.repo.get(id)
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Initiates the retrieval of data to display in the UI from the API
   *@private
   * @function
   * @returns {void}
   */
  private async lookup() {
    this.terminalLocations = await this.lookupState.getTerminalLocations()
  }

  /**
  * Instantiates a new Query Param instance with pre-set values required for a marketing search
  * @private
  * @function
  * @see {@link QueryParams}
  * @returns {QueryParams}
  */
  private newParams() {
    const queryParams = new QueryParams()
    queryParams.currentPage = 1
    queryParams.excludeBanned = true
    queryParams.hasPhone = true
    queryParams.pageSize = 0

    return queryParams
  }

 
}
