
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { MarketingSearchState } from '@/ts/states/marketing/children/marketingSearchState'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import MultiSelect from '@/components/multiselect/MultiSelect.vue'
  import { LookupState } from '@/ts/states/lookup/lookupState'
  import { DateFormatter } from '@/ts/formatters/dateFormatter'

  @Component({
    components: { MultiSelect }
  })
  export default class MarketingSearchForm extends Vue {

    /** PROPERTIES */

    @Prop({ default: false })
    public showRemove!: boolean

    @Prop()
    public state!: MarketingSearchState


    /** PRIVATE PROPERTIES */

    protected lookupState = new LookupState(this.$store)
    protected queryParams = this.state.queryParams


    /** COMPUTED PROPERTIES */

    protected get currentDateStandardlization() {
      return DateFormatter.currentDateStandardlization();
    }

    protected get advancedSearchIcon() {
      return this.state.isAdvancedSearch ? 'chevron-up' : 'chevron-down';
    }

    protected get advancedSearchText() {
      return `${this.state.isAdvancedSearch ? 'Hide' : 'Show'} advanced search`
    }

    protected get isBirthdaySelected() {
      if (this.queryParams.eventTypes != null) {
        return this.queryParams.eventTypes.findIndex(e => e.id == 6) > -1
      }

      return false
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get patron() {
      return this.state.patron
    }

    protected get patronIds() {
      return this.state.patronIds
    }

    protected get resetText() {
      return this.showRemove ? 'Remove search' : 'Clear search'
    }

    protected get searchText() {
      return (this.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Update Search'
    }

    protected get shouldShrink() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }


    /** EVENTS */

    protected async onOptOut() {
      try {
        console.log('todo')
        //if (this.patron != null) {
        //  this.patron.mobile = undefined
        //  await this.repo.save(this.patron)
        //  this.$emit('optOut', this.patron.id)
        //  this.onReset()
        //}
      }
      catch (error) {
        console.log(error)
      }
    }

    protected onReset() {
      this.$emit('reset', this.state.key)
    }

    protected onSubmit() {
      this.state.fetch(this.queryParams)
    }


    /** PRIVATE METHODS */

    protected maskedPhone(num: string) {
      return `${num.substr(0, 2)}${'*'.repeat(num.length - 6)}${num.substr(num.length - 4)}`
    }
  }
