import { ApiRepository } from './apiRepository'
import { HttpStatus } from '../enums/httpStatusCode'
import { Paths } from '../constants/paths'
import { Patron, IPatron } from '../models/patron'
import { QueryParams } from '../api/queryParams'

import axios from 'axios'
import * as qs from 'qs'

export class PatronRepository extends ApiRepository<Patron, IPatron> {

  constructor(path = Paths.PATRONS, type: new (i?: IPatron) => Patron = Patron) {
    super(path, type)
  }

  public async getPatronByMobile(phoneNumber: string): Promise<Patron | null> {
      const url = this.urlPath(this.path, null, [Paths.PATRON])

      const response = await axios.get(url, {
        params: { phoneNumber }
      })

      const iPatron = response.data as IPatron
      return new Patron(iPatron);
  }

  public async getPatrons(queryParams: QueryParams): Promise<number[]> {
      const response = await axios.get(this.path, {
        params: queryParams.toQueryString(),
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
      })

      return response.data
  }

  public async unsubscribe(id: number): Promise<boolean> {
    const url = this.urlPath(this.path, id, [Paths.UNSUBSCRIBE])
    const response = await axios.post(url)

    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

}
