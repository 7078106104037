
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { CommunicationRepository } from '@/ts/repositories/communicationRepository'
  import { SmsMessage } from '@/ts/models/smsMessage'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import { ValidationObserver } from 'vee-validate'
  import { IValidationState } from '@/ts/vee-validate/'

  import pluralize from 'pluralize'

  @Component
  export default class SendSmsForm extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: true })
    public editable!: boolean

    @Prop({ default: new Array<number>() })
    public patronIds!: number[]


  /** PRIVATE PROPERTIES */

    protected maxLength = 160
    protected message = ''
    protected repo = new CommunicationRepository()
    protected showClearWarning = false
    protected showModal = false
    protected showSendWarning = false


    private status = ViewStatus.NONE


  /** COMPUTED PROPERTIES */

    protected get characterCount() {
      return `${this.maxLength - this.message.length} of ${this.maxLength} characters`
    }

    protected get customers() {
      return pluralize('customer', this.patronIds.length, true)
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get sendText() {
      if (this.status == ViewStatus.IN_PROGRESS) {
        return 'Sending...'
      }

      return 'Send SMS to ' + this.customers
    }

    
  /** EVENTS  */

    protected onClear() {
      this.$emit('clear')
      this.message = ''
      this.status = ViewStatus.NONE
      this.showClearWarning = false
      this.showModal = false
      this.showSendWarning = false
    }

    protected onCancel() {
      this.$emit('cancel')
      this.onClose()
    }

    protected onClose() {
      this.status = ViewStatus.NONE
      this.showModal = false
    }

    protected async onSend() {
      console.log('todo')
    }

    protected async onSubmit() {
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      this.showSendWarning = await observer.validate()
    }

  /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null;
    }

  }

