
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Route } from '@/ts/models/route'

  import HeadPanel from '@/components/panels/HeadPanel.vue'
  import MarketingNavigation from './components/MarketingNavigation.vue'

  @Component({
    components: {
      HeadPanel,
      MarketingNavigation
    }
  })
  export default class Marketing extends Vue {

    /** PRIVATE PROPERTIES */

    @Prop()
    protected returnRoute?: Route
    /** LIFECYCLE */

  }

